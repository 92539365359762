<template>
  <v-app>
    <v-col cols="11" class="mx-auto">
      <div class="jumbotron p-5 text-left">
        <h5 class="display-1">Payment Schedule</h5>
      </div>
      <div class="mb-12">
        <v-col class="px-0">
          <p class="font-weight-bold mb-3 text-left">PAYMENT SCHEDULES</p>
          <hr />
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Payment ID</th>
                  <th>Batch Name</th>
                  <th>Batch ID</th>
                  <th>Date Approved</th>
                  <th>Amount approved</th>
                  <th>Reason</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="schedule in scheduledBatches" :key="schedule.id">
                  <td>{{ schedule.id }}</td>
                  <td>{{ schedule.batch }}</td>
                  <td>{{ schedule.batch_id }}</td>
                  <td>{{ schedule.date_created }}</td>
                  <td>{{ schedule.total_approved || 0 }}</td>
                  <td style="max-width: 150px" class="text-truncate">
                    {{
                      schedule.reason_approved_or_denied
                        ? schedule.reason_approved_or_denied
                        : "---------"
                    }}
                  </td>
                  <td v-if="schedule.is_approved">
                    <v-btn
                      disabled
                      color="grey darken-4"
                      class="white--text"
                      medium
                      >Scheduled</v-btn
                    >
                  </td>
                  <td v-else>
                    <v-btn
                      :to="{
                        name: 'schedule-batch',
                        params: { batch_id: schedule.id },
                      }"
                      color="green darken-4"
                      class="white--text"
                      medium
                    >
                      <small>Schedule</small>
                      <br />
                      <small>Payment</small>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </div>
    </v-col>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "schedule-batch-list",
  created() {
    this.fetchSchedules();
  },
  computed: {
    ...mapGetters({
      scheduledBatches: "claimModule/schedules",
    }),
  },
  methods: {
    ...mapActions({ fetchSchedules: "claimModule/fetchSchedules" }),
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
</style>
